import axios from 'axios'
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'
import _ from 'lodash'

const login = ({ email, password }) =>
  handleResponse(axios.post('/users/sign_in', { user: { email, password } }))

const createUser = ({ email, password, phone, first_name, last_name, owner }) =>
  handleResponse(axios.post('/users', {
    user: { email, password, phone, first_name, last_name },
    owner,
  }))

const createStudio = studio => handleResponse(axios.post('/studios', { studio }))

const syncStudio = () => handleResponse(axios.post(`/studios/sync`))

const updateStudio = ({ studio, attributes }) =>
  handleResponse(axios.put(`/studios/${studio.id}`, { studio: { ...attributes } }))

const createPasswordReset = email =>
  handleResponse(axios.post('/users/password', { user: { email } }))

const updatePassword = ({ reset_password_token, password }) =>
  handleResponse(axios.put('/users/password', { user: { reset_password_token, password } }))

const addSubscription = selectedPlan =>
  handleResponse(axios.post('/subscriptions/choose', { subscription: { plan_type: selectedPlan } }))

const persistSource = stripeSource =>
  handleResponse(axios.post('/stripe_sources', { stripe_source: stripeSource }))

const changeSource = stripeSource =>
  handleResponse(axios.put('/stripe_sources', { stripe_source: stripeSource }))

const submitSubscription = selectedPlan =>
  handleResponse(axios.post('/subscriptions', { subscription: { plan_type: selectedPlan } }))

const changeSubscription = selectedPlan =>
  handleResponse(axios.put('/subscriptions', { subscription: { plan_type: selectedPlan } }))

const cancelSubscription = () =>
  handleResponse(axios.put('/subscriptions/cancel'))

const logout = () => handleResponse(axios.delete('/users/sign_out'), {})

const validatePromoCode = promo =>
  handleResponse(axios.post('/accounts/validate_promo', { promo }))

const sendDownloadLink = mobileNumber =>
  handleResponse(axios.post('/download_link', { mobile_number: mobileNumber }))

const handleResponse = responsePromise =>
  responsePromise.then(({ data }) => ({ ...data, ok: true })).catch(({ response }) => {
    let message, status
    if (!response) {
      message = 'Check your network connection and try again.'
      status = 408
    } else {
      message =
        response.data.message ||
        response.data.error ||
        response.data.errors ||
        `Your request failed with error code ${response.status}`
      status = response.status
    }
    if (typeof message !== 'string') {
      message = _.reduce(
        message,
        (sum, value, key) => (sum += `${_.capitalize(key)} ${value}.`),
        ''
      )
    }
    return { status, message }
  })


export {
  login,
  createUser,
  createStudio,
  syncStudio,
  createPasswordReset,
  updatePassword,
  addSubscription,
  persistSource,
  changeSource,
  updateStudio,
  validatePromoCode,
  submitSubscription,
  changeSubscription,
  cancelSubscription,
  logout,
  sendDownloadLink,
}
