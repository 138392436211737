import CreateResourceScreenStyles from './CreateResourceScreenStyles'
import { Colors, Fonts, Metrics } from '../../Themes'

export default {
  ...CreateResourceScreenStyles,
  checkBoxListItem: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginTop: Metrics.baseMargin,
    minHeight: Metrics.touchTarget,
    paddingBottom: Metrics.baseMargin,
    paddingLeft: Metrics.doubleBaseMargin,
  },
  checkBoxLabel: {
    alignItems: 'center',
    color: Colors.black75,
    display: 'flex',
    fontSize: Fonts.size.medium,
    fontWeight: Fonts.weight.medium,
    lineHeight: 1.2,
    letterSpacing: 0.2,
    marginLeft: Metrics.baseMargin + Metrics.smallMargin,
  },
  checkBoxGroup: {
    borderBottomColor: Colors.black50,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    display: 'flex',
    flexDirection: 'column',
    height: '36vh',
    minWidth: '100%',
    marginBottom: Metrics.section,
    overflowY: 'scroll',
  },
}
