import { ApplicationStyles, Colors, Fonts, Metrics } from '../../Themes'

export default {
  ...ApplicationStyles.screen,
  boldText: {
    ...ApplicationStyles.screen.sectionText,
    fontWeight: 'bold',
  },
  label: {
    marginBottom: Metrics.doubleBaseMargin,
    marginTop: Metrics.baseMargin,
    display: 'flex',
    alignItems: 'center',
  },
  bottomLabel: {
    marginBottom: Metrics.section,
    marginTop: Metrics.baseMargin,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    boxShadow: 'none',
    color: Colors.black50,
    fontSize: Fonts.size.regular,
    fontWeight: Fonts.weight.regular,
    letterSpacing: -0.4,
    lineHeight: 1.2,
    marginBottom: 0,
    minHeight: Metrics.touchTarget,
    paddingLeft: Metrics.doubleBaseMargin,
    backgroundColor: 'transparent',
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  linksContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: Metrics.doubleBaseMargin,
  },
  dividingSpace: {
    display: 'block',
    width: '70%',
    marginTop: Metrics.doubleBaseMargin,
    marginBottom: Metrics.section,
    borderBottomColor: Colors.black15,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  verticalDivider: {
    borderLeftColor: Colors.black15,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    marginTop: Metrics.section,
  },
  activateCardsContainer: {
    justifyContent: 'center',
    marginBottom: Metrics.baseMargin,
  },
  activateCard: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  activateCardHeader: {
    color: Colors.black75,
    fontSize: Fonts.size.h5,
    fontWeight: Fonts.weight.medium,
    letterSpacing: 0.3,
    marginBottom: Metrics.section,
    textAlign: 'center',
  },
  codeContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: Metrics.section,
    marginBottom: Metrics.baseMargin,
  },
  code: {
    color: Colors.black75,
    backgroundColor: Colors.infoBackgroundTint,
    fontSize: Fonts.size.medium,
    height: Metrics.touchTarget / 2,
    padding: 2,
    borderRadius: Metrics.baseMargin,
  },
  codeLabel: {
    color: Colors.black75,
    fontSize: Fonts.size.medium,
    height: Metrics.touchTarget / 2,
  },
  activateLink: {
    color: Colors.primaryTheme,
    fontSize: Fonts.size.medium,
    fontWeight: Fonts.weight.regular,
    letterSpacing: -0.3,
  },
  activateFooter: {
    alignItems: 'center',
    marginBottom: Metrics.fatMargin,
  },
  accountLinksContainer: {
    flexDirection: 'column',
    width: '100%',
  },
}
