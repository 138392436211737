const headers = accessToken => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: accessToken,
});

export const get = ({ url, token }) => {
  return fetch(url, {
    method: 'GET',
    headers: headers(token),
  }).then(res => {
    if (!res.ok) return res.json().then(e => Promise.reject(e));
    return res.json();
  });
};

export const post = ({ url, token, payload }) => {
  return fetch(url, {
    method: 'POST',
    headers: headers(token),
    body: payload ? JSON.stringify(payload) : '',
  }).then(res => {
    if (!res.ok) return res.json().then(e => Promise.reject(e));
    return res.json();
  });
};
