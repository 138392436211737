import { Metrics } from '../../Themes'

export default {
  iconContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    marginLeft: Metrics.smallMargin / 2,
  },
}
