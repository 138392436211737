import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Card, Button } from 'antd';
import downloadCSV from 'download-csv';
import * as dataAnalyticsService from '../sources/dataAnalyticsSource';
import { dataAnalyticsNormalizers } from '../utils/normalizers';
import {
  SORTING_ORDERS,
  FILE_NAMES,
  TABLE_COLUMNS,
  DATE_FORMATS,
  DEFAULTS,
} from '../utils/constants';
import FilterDropDown from '../Components/FilterDropDown';
import { formatDateTime } from '../utils/dateTime';
import antNotifications from '../utils/ant_notifications';
import BackButton from '../Components/BackButton';

export default class DataAnalytics extends Component {
  constructor(props) {
    super(props);
    const DEFAULT_SORT = { sort: 'class_date_time', order: SORTING_ORDERS.descend };
    this.state = {
      data: [],
      loading: false,
      pageNumber: 1,
      totalRecords: 0,
      recordsPerPage: DEFAULTS.PAGINATION.LIMIT,
      sort: DEFAULT_SORT,
      downloading: false,
      loadingClasses: false,
      classes: [],
      loadingInstructors: false,
      instructors: [],
      loadingLocations: false,
      locations: [],
      requestedInstructors: null,
      acceptedInstructors: null,
      classNames: null,
      selectedLocations: null,
      date: null,
    };
  }

  fetchLocations() {
    this.setState({ loadingLocations: true }, () => {
      const { studio = {}, userToken = '' } = this.props;
      dataAnalyticsService
        .fetchLocations({
          token: userToken,
          payload: {
            studioId: studio.id,
          },
        })
        .then(({ locations = [] }) => {
          this.setState({
            loadingLocations: false,
            locations: dataAnalyticsNormalizers.locationOptions(locations),
          });
        })
        .catch(() => this.setState({ loadingLocations: false }));
    });
  }

  fetchInstructors() {
    this.setState({ loadingInstructors: true }, () => {
      const { studio = {}, userToken = '' } = this.props;
      dataAnalyticsService
        .fetchInstructors({
          token: userToken,
          payload: {
            studioId: studio.id,
          },
        })
        .then((instructors = []) => {
          this.setState({
            loadingInstructors: false,
            instructors: dataAnalyticsNormalizers.instructorOptions(instructors),
          });
        })
        .catch(() => this.setState({ loadingInstructors: false }));
    });
  }

  fetchClassNames() {
    this.setState({ loadingClasses: true }, () => {
      const { studio = {}, userToken = '' } = this.props;
      dataAnalyticsService
        .fetchClassNames({
          token: userToken,
          payload: {
            studioId: studio.id,
          },
        })
        .then(({ class_templates: classes = [] }) => {
          this.setState({
            loadingClasses: false,
            classes: dataAnalyticsNormalizers.classOptions(classes),
          });
        })
        .catch(() => this.setState({ loadingClasses: false }));
    });
  }
  fetchData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        const {
          pageNumber = 1,
          recordsPerPage = DEFAULTS.PAGINATION.LIMIT,
          sort = null,
          requestedInstructors = null,
          acceptedInstructors = null,
          classNames = null,
          selectedLocations = null,
          date = null,
        } = this.state;
        dataAnalyticsService
          .fetchDataAnalyticsSummary({
            token: this.props.userToken,
            queryParams: {
              page: pageNumber,
              limit: recordsPerPage,
            },
            payload: {
              ...sort,
              requested_instructors: requestedInstructors,
              accepted_instructors: acceptedInstructors,
              class_names: classNames,
              locations: selectedLocations,
              class_start_date: date ? formatDateTime(date.start, DATE_FORMATS.YYYY_MM_DD) : null,
              class_end_date: date ? formatDateTime(date.end, DATE_FORMATS.YYYY_MM_DD) : null,
            },
          })
          .then(data =>
            this.setState({
              data: data.content.map(dataAnalyticsNormalizers.subRequests),
              loading: false,
              totalRecords: data.pagination['total_entries'],
            })
          )
          .catch(e => this.setState({ loading: false }));
      }
    );
  }

  handleSorting({ column, order }) {
    this.setState(
      {
        sort:
          column && order
            ? {
                sort: column.key,
                order: SORTING_ORDERS[order],
              }
            : null,
      },
      () => this.fetchData()
    );
  }

  handleTableChange = (pagination, filter, sorter) => {
    this.handleSorting(sorter);
  };

  handleDownloadClick = () => {
    this.setState({ downloading: true }, () => {
      const { userToken = '' } = this.props;
      const {
        sort,
        acceptedInstructors,
        date,
        requestedInstructors,
        selectedLocations,
        classNames,
      } = this.state;
      dataAnalyticsService
        .fetchDataAnalyticsSummary({
          token: userToken,
          paginated: false,
          payload: {
            ...sort,
            requested_instructors: requestedInstructors,
            accepted_instructors: acceptedInstructors,
            class_names: classNames,
            locations: selectedLocations,
            class_start_date: date ? formatDateTime(date.start, DATE_FORMATS.YYYY_MM_DD) : null,
            class_end_date: date ? formatDateTime(date.end, DATE_FORMATS.YYYY_MM_DD) : null,
          },
        })
        .then(data => {
          const { content = [] } = data;
          if (!content.length) {
            antNotifications.error({
              message: 'No data available',
            });
          } else {
            const normalizedData = content.map(dataAnalyticsNormalizers.CSV);
            downloadCSV(normalizedData, null, FILE_NAMES.dataAnalyticsReport());
          }
          this.setState({ downloading: false });
        })
        .catch(e => {
          const { errors = [] } = e;
          this.setState({ downloading: false });
          if (errors.length) {
            errors.forEach(error =>
              antNotifications.error({
                message: error,
              })
            );
          } else antNotifications.error();
          throw e;
        });
    });
  };

  handleFilterChange = (data, field) => {
    const shouldUpdate = this.state[field] !== data;
    this.setState({ [field]: data, pageNumber: 1 }, () => {
      if (shouldUpdate) this.fetchData();
    });
  };

  componentDidMount() {
    this.fetchData();
    this.fetchClassNames();
    this.fetchInstructors();
    this.fetchLocations();
  }

  render() {
    const {
      data = [],
      loading = false,
      totalRecords = 0,
      recordsPerPage = DEFAULTS.PAGINATION.LIMIT,
      downloading = false,
      loadingClasses = false,
      classes = [],
      loadingInstructors = false,
      instructors = [],
      loadingLocations = false,
      locations = [],
      pageNumber = 1,
    } = this.state;
    const { studio = {} } = this.props;
    return (
      <div className="data-analytics-container">
        <BackButton />
        <Card bordered={false}>
          <div className="top-container">
            <div className="table-title">{studio.name || 'Data Analytics'}</div>
            <Button
              type="primary"
              size="large"
              onClick={this.handleDownloadClick}
              loading={downloading}
            >
              Download Report
            </Button>
          </div>
          <div className="filter-container">
            <FilterDropDown
              placeholder="Date Range"
              disabled={loading}
              datePicker={true}
              onFiltersChange={date => this.handleFilterChange(date, 'date')}
            />
            <FilterDropDown
              placeholder="Req. Instructor"
              disabled={loading}
              loading={loadingInstructors}
              options={instructors}
              onFiltersChange={requestedInstructors =>
                this.handleFilterChange(requestedInstructors, 'requestedInstructors')
              }
            />
            <FilterDropDown
              placeholder="Accepting Instructor"
              disabled={loading}
              loading={loadingInstructors}
              options={instructors}
              onFiltersChange={acceptedInstructors =>
                this.handleFilterChange(acceptedInstructors, 'acceptedInstructors')
              }
            />
            <FilterDropDown
              placeholder="Class Name"
              disabled={loading}
              loading={loadingClasses}
              options={classes}
              onFiltersChange={classNames => this.handleFilterChange(classNames, 'classNames')}
            />
            <FilterDropDown
              placeholder="Location"
              disabled={loading}
              loading={loadingLocations}
              options={locations}
              onFiltersChange={selectedLocations =>
                this.handleFilterChange(selectedLocations, 'selectedLocations')
              }
            />
          </div>
          <Table
            loading={loading}
            dataSource={data}
            columns={TABLE_COLUMNS.DATA_ANALYTICS}
            pagination={false}
            onChange={this.handleTableChange}
            scroll={{ x: 2000 }}
          />
          <div className="pagination-container">
            <Pagination
              disabled={loading || totalRecords < recordsPerPage}
              pageSizeOptions={['5', '10', '20']}
              total={totalRecords}
              defaultPageSize={recordsPerPage}
              onChange={pageNumber => this.setState({ pageNumber }, () => this.fetchData())}
              current={pageNumber}
            />
          </div>
        </Card>
      </div>
    );
  }
}

DataAnalytics.propTypes = {
  userToken: PropTypes.string,
  studio: PropTypes.object,
};

DataAnalytics.defaultProps = {
  userToken: '',
  studio: {},
};
