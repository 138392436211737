import { Colors } from '../../../Themes'

export default {
  boxIcon: {
    fill: Colors.black50,
  },
  infoIcon: {
    cursor: 'pointer',
    fill: Colors.primaryTheme,
  },
  closeIcon: {
    fill: Colors.snow,
  },
}
