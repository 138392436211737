import { formatDateTime } from './dateTime';
import {
  DATE_FORMATS,
  TIME_FORMATS,
  STATUS_CONSTANTS,
} from '../utils/constants';

export const dataAnalyticsNormalizers = {
  instructorOptions: instructors =>
    instructors.map(instructor => ({
      id: instructor.id,
      title: instructor.name || '',
    })),
  locationOptions: locations =>
    locations.map(location => ({
      id: location.id,
      title: location.name || '',
    })),
  classOptions: classNames =>
    classNames.map(className => ({
      id: className.id,
      title: className.name || '',
    })),
  subRequests: subRequest => ({
    id: subRequest['id'],
    key: subRequest['sub_request_id'],
    className: subRequest['class_name'] || '-',
    classDate: subRequest['class_date_time']
      ? formatDateTime(subRequest['class_date_time'], DATE_FORMATS.MMMM_dd_yyyy, true)
      : '-',
    classTime: subRequest['class_date_time']
      ? formatDateTime(subRequest['class_date_time'], TIME_FORMATS['24_HOURS'], true)
      : '-',
    locationName: subRequest['location_name'] || '-',
    requestedBy: subRequest['requested_by'] || '-',
    requestedDate: subRequest['requested_date_time']
      ? formatDateTime(subRequest['requested_date_time'], DATE_FORMATS.MMMM_dd, true)
      : '-',
    acceptedDate: subRequest['accepted_date_time']
      ? formatDateTime(subRequest['accepted_date_time'], DATE_FORMATS.MMMM_dd, true)
      : '-',
    acceptedBy: subRequest['accepted_by'] || '-',
    status: STATUS_CONSTANTS.SUB_REQUEST[subRequest.status] || '-',
    comments: subRequest['comment'] || '-',
  }),
  CSV: subRequest => ({
    ID: `"${subRequest['id']}"`,
    'Sub Request ID': `"${subRequest['sub_request_id']}"`,
    'Class Name': `"${subRequest['class_name'] || '-'}"`,
    'Class Date': subRequest['class_date_time']
      ? `"${formatDateTime(subRequest['class_date_time'], DATE_FORMATS.MMMM_dd_yyyy,true)}"`
      : '-',
    'Class Time': subRequest['class_date_time']
      ? `"${formatDateTime(subRequest['class_date_time'], TIME_FORMATS['24_HOURS'], true)}"`
      : '-',
    'Location Name': `"${subRequest['location_name'] || '-'}"`,
    'Requested By': `"${subRequest['requested_by'] || '-'}"`,
    'Requested Date': subRequest['requested_date_time']
      ? `"${formatDateTime(subRequest['requested_date_time'], DATE_FORMATS.MMMM_dd, true)}"`
      : '-',
    'Accepted Date': subRequest['accepted_date_time']
      ? `"${formatDateTime(subRequest['accepted_date_time'], DATE_FORMATS.MMMM_dd, true)}"`
      : '-',
    'Accepted By': `"${subRequest['accepted_by'] || '-'}"`,
    Status: `"${STATUS_CONSTANTS.SUB_REQUEST[subRequest.status] || '-'}"`,
    Comments: `"${subRequest['comment'] || '-'}"`,
  }),
};
