import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { createUser, logout } from '../services/api';
import { emptyFieldValidation, getLabelStyle, validateEmail } from '../utils/inputValidation';

import { NotificationManager, NotificationContainer } from 'react-notifications';
import SetupHeader from '../Components/SetupHeader';
import PrimaryButton from '../Components/PrimaryButton';
import LinkButton from '../Components/LinkButton';
import PhoneInput from '../Components/PhoneInput';

import styles from './Styles/CreateResourceScreenStyles';
import Stepper from '../Components/Stepper';
import { STEPS, URLS } from '../utils/constants';
import BackButton from '../Components/BackButton';

class CreateAccountScreen extends Component {
  static propTypes = {
    email: PropTypes.string,
  };

  state = {
    errorFields: [],
    isOwner: false,
    user: {
      email: '',
      password: '',
      phone: '',
      first_name: '',
      last_name: '',
      passwordConfirmation: '',
    },
    owner: false,
  };

  componentDidMount = () => {
    const { email, owner } = this.props;
    if (email !== null)
      this.setState({
        user: { ...this.state.user, email },
        owner: true,
      });
    else if (owner) this.setState({ owner: true });
  };

  goToSignIn = () => (window.location.pathname = '/users/sign_in');

  handleSubmit = () => {
    const { user, owner } = this.state;
    if (this.validate(user)) {
      const { email, password, phone, first_name, last_name } = user;
      createUser({ email, password, phone, first_name, last_name, owner }).then(response => {
        this.handleResponse(response);
      });
    }
  };

  updateUserInput = (input, attr) => {
    const updateValue = input.target ? input.target.value : input;
    this.setState({ user: { ...this.state.user, [attr]: updateValue } });
  };

  validate = user => {
    const errorFields = emptyFieldValidation(user);
    if (errorFields.length !== 0) {
      NotificationManager.error('Missing required fields');
      this.setState({ errorFields });
    } else if (user.password !== user.passwordConfirmation) {
      NotificationManager.error("Password and password confirmation don't match");
      this.setState({ errorFields: ['password', 'passwordConfirmation'] });
    } else if (!validateEmail(user.email)) {
      NotificationManager.error('Please enter a valid email.');
      this.setState({ errorFields: ['email'] });
    } else if (!isPossiblePhoneNumber(user.phone)) {
      NotificationManager.error('Phone number is not valid');
      this.setState({ errorFields: ['phone'] });
    } else {
      return true;
    }
  };

  handleResponse = response => {
    if (response.ok && this.state.owner) {
      window.location.href = '/subscriptions/new';
    } else if (response.ok) {
      logout().then(() => window.location.replace(URLS.ACCOUNT_SETUP_INSTRUCTOR));
    } else {
      NotificationManager.error(response.message);
    }
  };

  render() {
    const { user, errorFields, owner = false } = this.state;

    return (
      <Fragment>
        <div className="screen-background">
          <BackButton />
          <form className="main-container no-padding" onSubmit={this.handleSubmit}>
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            {owner ? (
              <Stepper steps={STEPS.ACCOUNT_CREATION} current={0} className="account-stepper" />
            ) : null}
            <div className="create-account-form-container">
              <div style={styles.headerTextWrapper}>
                <p style={styles.headerMainText}>Create Your Account</p>
              </div>
              <label
                className="input-label"
                style={getLabelStyle(errorFields, 'first_name', styles.label)}
              >
                <input
                  placeholder="First Name"
                  style={styles.input}
                  type="text"
                  value={user.first_name}
                  onChange={input => this.updateUserInput(input, 'first_name')}
                  autoComplete="given-name"
                />
              </label>
              <label
                className="input-label"
                style={getLabelStyle(errorFields, 'last_name', styles.label)}
              >
                <input
                  placeholder="Last Name"
                  style={styles.input}
                  type="text"
                  value={user.last_name}
                  onChange={input => this.updateUserInput(input, 'last_name')}
                  autoComplete="family-name"
                />
              </label>
              <label
                className="input-label"
                style={getLabelStyle(errorFields, 'email', styles.label)}
              >
                <input
                  placeholder="Email"
                  style={styles.input}
                  type="text"
                  value={user.email}
                  onChange={input => this.updateUserInput(input, 'email')}
                  autoComplete="email"
                />
              </label>
              <label
                className="input-label"
                style={getLabelStyle(errorFields, 'password', styles.label)}
              >
                <input
                  placeholder="Password"
                  style={styles.input}
                  type="password"
                  value={user.password}
                  onChange={input => this.updateUserInput(input, 'password')}
                  autoComplete="new-password"
                />
              </label>
              <label
                className="input-label"
                style={getLabelStyle(errorFields, 'passwordConfirmation', styles.label)}
              >
                <input
                  placeholder="Password Confirmation"
                  style={styles.input}
                  type="password"
                  value={user.passwordConfirmation}
                  onChange={input => this.updateUserInput(input, 'passwordConfirmation')}
                  autoComplete="new-password"
                />
              </label>
              <div
                className="input-label"
                style={getLabelStyle(errorFields, 'phone', styles.bottomLabel)}
              >
                <PhoneInput
                  handleInputUpdate={(input) => this.updateUserInput(input, 'phone')}
                  phoneNumber={user.phone}
                />
              </div>

              <div className="buttons-container" style={styles.buttonsContainer}>
                <PrimaryButton
                  onPress={this.handleSubmit}
                  text="Create Your Account"
                  block
                  newCtaButton
                />
              </div>
              <div style={styles.linksContainer}>
                <LinkButton linkAction={this.goToSignIn} linkText={'Already have an account?'} />
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default CreateAccountScreen;
