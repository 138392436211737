import Fonts from './Fonts'
import Metrics from './Metrics'
import Colors from './Colors'

const ApplicationStyles = {
  screen: {
    page: {
      backgroundColor: Colors.background,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100vh',
      paddingLeft: Metrics.baseMargin * 12.5,
      paddingRight: Metrics.baseMargin * 12.5,
      paddingTop: Metrics.baseMargin * 10,
    },
    mainContainer: {
      backgroundColor: Colors.snow,
      flexDirection: 'column',
      minHeight: 528,
      paddingLeft: Metrics.fatMargin * 6,
      paddingRight: Metrics.fatMargin * 6,
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    container: {
      flex: 1,
    },
    shadowContainer: {
      flex: 1,
      paddingTop: Metrics.baseMargin,
      margin: Metrics.doubleBaseMargin,
      justifyContent: 'center',
      backgroundColor: Colors.snow,
      shadowOffset: {
        height: 0.0,
        width: 0.0,
      },
      shadowColor: Colors.authShadowGrey,
      shadowRadius: 6.0,
      shadowOpacity: 1,
    },
    appHeader: {
      height: 160,
      backgroundColor: Colors.primaryThemeLight,
      paddingBottom: Metrics.baseMargin,
      paddingRight: Metrics.baseMargin,
      paddingLeft: Metrics.baseMargin,
    },
    section: {
      margin: Metrics.section,
    },
    sectionText: {
      ...Fonts.style.medium,
      paddingVertical: Metrics.baseMargin,
      color: Colors.charcoal,
    },
    subtitle: {
      color: Colors.snow,
      padding: Metrics.smallMargin,
      marginHorizontal: Metrics.smallMargin,
    },
    headerText: {
      ...Fonts.style.h2,
      color: Colors.charcoal,
      textAlign: 'center',
      paddingBottom: Metrics.doubleBaseMargin,
    },
    lightHeaderText: {
      ...Fonts.style.h2,
      color: Colors.snow,
      textAlign: 'center',
      paddingTop: Metrics.doubleBaseMargin,
      paddingBottom: Metrics.doubleBaseMargin,
    },
    titleText: {
      ...Fonts.style.h3,
      color: Colors.charcoal,
      textAlign: 'center',
    },
    lightTitleText: {
      ...Fonts.style.h3,
      color: Colors.snow,
      textAlign: 'center',
    },
    horizontalLayout: {
      flexDirection: 'row',
    },
    touchTargetContainer: {
      alignItems: 'center',
      flex: 0,
      height: 44,
      justifyContent: 'center',
      width: 44,
    },
    headerMainText: {
      color: Colors.black75,
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 0.3,
      lineHeight: 1.2,
      textAlign: 'center',
    },
    promoText: {
      color: Colors.black75,
      fontSize: Fonts.size.h1,
      fontWeight: Fonts.weight.semibold,
      letterSpacing: 0.3,
      lineHeight: 1.2,
      marginBottom: Metrics.fatMargin,
      textAlign: 'left',
    },
    headerTextWrapper: {
      alignItems: 'center',
      lineHeight: 1.2,
      marginBottom: Metrics.section,
      justifyContent: 'center',
    },
    infoContainer: {
      justifyContent: 'center',
      paddingBottom: Metrics.section,
    },
    infoText: {
      color: Colors.black75,
      fontSize: Fonts.size.regular,
      fontWeight: Fonts.weight.light,
      letterSpacing: 0.3,
      textAlign: 'center',
    },
    setupInputWrapper: {
      borderBottomColor: Colors.black22,
      borderBottomWidth: Metrics.thinBorder,
      paddingHorizontal: Metrics.doubleBaseMargin,
      marginBottom: Metrics.tripleBaseMargin,
    },
    warningText: {
      color: Colors.black75,
      fontSize: Fonts.size.medium,
      fontWeight: Fonts.weight.light,
      padding: Metrics.doubleBaseMargin,
      width: '100%',
    },
  },
  darkLabelContainer: {
    padding: Metrics.smallMargin,
    paddingBottom: Metrics.baseMargin,
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
    marginBottom: Metrics.baseMargin,
  },
  darkLabel: {
    fontFamily: Fonts.type.bold,
    color: Colors.snow,
  },
  groupContainer: {
    margin: Metrics.smallMargin,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}

export default ApplicationStyles
