import React from 'react';

export default () => (
  <div
    style={{
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      color: '#1890ff',
      cursor: 'pointer',
      width: 'fit-content',
      height: 40,
    }}
    onClick={() => window.history.back()}
  >
    <img src={require('../../assets/images/stubs/arrow_right_alt-24px.svg')} />
    <div style={{ margin: '0 4px' }}>Back</div>
  </div>
);
