import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { persistSource, changeSource } from '../services/api';
import { emptyFieldValidation, getLabelStyle, hasError } from '../utils/inputValidation';

import { injectStripe, Elements, StripeProvider, CardElement } from 'react-stripe-elements';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { RegionDropdown } from 'react-country-region-selector';
import SetupHeader from '../Components/SetupHeader';
import PrimaryButton from '../Components/PrimaryButton';
import LinkButton from '../Components/LinkButton';

import styles from './Styles/PaymentScreenStyles';
import { Colors, Metrics } from '../Themes';
import Stepper from '../Components/Stepper';
import { STEPS } from '../utils/constants';
import BackButton from '../Components/BackButton';

class PaymentSetupScreen extends Component {
  state = {
    elementState: { empty: true },
    errorFields: [],
    owner: {
      email: '',
      name: '',
      address: {
        city: '',
        line1: '',
        line2: '',
        state: '',
        country: 'US',
      },
    },
    sourceUpdated: false,
  };

  componentDidMount = () => {
    const { customerEmail } = this.props;
    const { owner } = this.state;
    this.setState({ owner: { ...owner, email: customerEmail } });
  };

  submit = event => {
    event.preventDefault();
    const { owner } = this.state;

    if (this.validate()) {
      this.props.stripe.createSource({ type: 'card', owner }).then(this.handleStripeCreateSource);
    }
  };

  handleCancel = () => (window.location.href = '/accounts');

  validate = () => {
    const {
      owner: { email, name, address },
      elementState: { empty: isCardElementEmpty },
    } = this.state;

    const fields = { ...address, email, name };
    // remove line2 from required fields
    delete fields.line2;
    const errorFields = emptyFieldValidation(fields);

    if (errorFields.length !== 0) {
      if (isCardElementEmpty) errorFields.push('cardElement');
      NotificationManager.error('Missing Required Fields');
      this.setState({ errorFields });
    } else {
      return true;
    }
  };

  handleStripeCreateSource = ({ error, source }) => {
    const endPoint = this.props.isEdit ? changeSource : persistSource;
    return error
      ? NotificationManager.error(error.message)
      : endPoint({ source_id: source.id }).then(this.handlePersistSource);
  };

  handlePersistSource = ({ ok = false, redirect_url: redirectUrl, message }) => {
    if (ok) {
      if (!this.props.isModal) {
        if (this.props.isEdit) this.setState({ sourceUpdated: true });
        else window.location.pathname = redirectUrl;
      } else {
        NotificationManager.success('Payment method updated');
        this.props.handleCancel && this.props.handleCancel();
      }
    } else {
      NotificationManager.error(message);
    }
  };

  updateOwner = (input, attr) => {
    const { owner } = this.state;
    const newAttr = { [attr]: input.target.value };
    this.setState({ owner: { ...owner, ...newAttr } });
  };

  updateOwnerAddress = (input, attr) => {
    const { owner } = this.state;
    const { address } = owner;
    const newAttr = { [attr]: input.target.value };
    this.setState({
      owner: {
        ...owner,
        address: {
          ...address,
          ...newAttr,
        },
      },
    });
  };

  updateRegion = region => {
    const { owner } = this.state;
    const { address } = owner;
    this.setState({
      owner: {
        ...owner,
        address: {
          ...address,
          state: region,
        },
      },
    });
  };

  updatePostalCode = postal_code => {
    const { owner } = this.state;
    const { address } = owner;
    this.setState({
      owner: {
        ...owner,
        address: {
          ...address,
          postal_code,
        },
      },
    });
  };

  updateElementState = elementState => this.setState({ elementState });

  renderInput = (placeholder, value, onChange, stateValue) => (
    <input
      placeholder={placeholder}
      style={styles.input}
      type="text"
      value={value}
      onChange={input => onChange(input, stateValue)}
    />
  );

  renderUpdateSuccess = () => (
    <Fragment>
      <div style={styles.headerTextWrapper}>
        <p style={styles.headerMainText}>Your payment information has been successfully updated</p>
      </div>
      <div style={styles.linksContainer}>
        <LinkButton linkAction={this.handleCancel} linkText={'Back to Account Settings'} />
      </div>
    </Fragment>
  );

  ScreenContainer = ({ children = [] }) => {
    return (
      <div className="screen-background">
        <BackButton />
        <div className="main-container no-padding" style={{ marginBottom: 0 }}>
          <div className="fix-align">
            <NotificationContainer />
          </div>
          <SetupHeader />
          {!this.props.isEdit && (
            <Stepper steps={STEPS.ACCOUNT_CREATION} current={2} className="account-stepper" />
          )}
          {children}
        </div>
        <img
          src={require('../../assets/images/stubs/powered_by_stripe@3x.svg')}
          className="stripe-logo"
        />
      </div>
    );
  };

  PaymentFormContent = () => {
    const { isEdit, isModal } = this.props;
    const { owner, errorFields, elementState, sourceUpdated } = this.state;

    const cardElementStyle = elementState.error
      ? getLabelStyle(['cardElement'], 'cardElement', styles.stripeElementLabel)
      : getLabelStyle(errorFields, 'cardElement', styles.stripeElementLabel);

    const buttonText = isEdit || isModal ? 'Update Card Information' : 'Save Card Information';
    const buttonContainerStyle = isEdit
      ? { ...styles.buttonsContainer, marginBottom: '44px' }
      : styles.buttonsContainer;

    if (!isModal && isEdit && sourceUpdated) return this.renderUpdateSuccess();

    return (
      <Fragment>
        {isModal && (
          <Fragment>
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader className="no-padding" />
          </Fragment>
        )}
        <div className="credit-card-form-container">
          <div style={styles.headerTextWrapper}>
            <p style={styles.headerMainText}>
              {!isEdit ? 'Enter' : 'Update'} Your Payment Information
            </p>
          </div>
          <label className="stripe-element-label" style={{ ...cardElementStyle, marginBottom: 8 }}>
            <CardElement onChange={this.updateElementState} style={styles.stripeElement} />
          </label>
          <label className="input-label" style={getLabelStyle(errorFields, 'name', styles.label)}>
            {this.renderInput('Card Holder Name', owner.name, this.updateOwner, 'name')}
          </label>
          <label className="input-label" style={getLabelStyle(errorFields, 'line1', styles.label)}>
            {this.renderInput(
              'Billing Address Line 1',
              owner.address.line1,
              this.updateOwnerAddress,
              'line1'
            )}
          </label>
          <div style={styles.addressWrapper} className="address-wrapper">
            <label
              className="input-label"
              style={{
                ...styles.label,
                minWidth: '45%',
                marginRight: Metrics.doubleBaseMargin,
              }}
            >
              {this.renderInput(
                'Billing Address Line 2',
                owner.address.line2,
                this.updateOwnerAddress,
                'line2'
              )}
            </label>
            <label
              className="input-label city-input-label"
              style={getLabelStyle(errorFields, 'line1', styles.label)}
            >
              {this.renderInput('City', owner.address.city, this.updateOwnerAddress, 'city')}
            </label>
          </div>
          <label style={styles.stateSelectionLabel} className="state-input-label">
            <RegionDropdown
              country="United States"
              value={owner.address.state}
              onChange={region => this.updateRegion(region)}
              defaultOptionLabel={'Select State'}
              classes="credit-card-state-selector"
            />
            {hasError(errorFields, 'state') ? (
              <p style={styles.input}>* Please Select a State</p>
            ) : null}
          </label>
          <div style={buttonContainerStyle} className="buttons-container">
            {(isModal || isEdit) && (
              <PrimaryButton
                color={Colors.black10}
                onPress={this.props.handleCancel || this.handleCancel}
                text="Cancel"
                textColor={Colors.black50}
                newCtaButton
              />
            )}
            <PrimaryButton
              onPress={this.submit}
              text={buttonText}
              newCtaButton
              {...((isModal || isEdit) ? {} : { block: true })}
            />
          </div>
          {!isEdit && (
            <p style={{ ...styles.warningText, fontSize: 12 }}>
              * Your credit card will not be charged until the end of the trial period. If you have
              a promo code, you will have the opportunity to enter it before confirming your
              subscription.
            </p>
          )}
        </div>
        {isModal && (
          <img
            src={require('../../assets/images/stubs/powered_by_stripe@3x.svg')}
            className="stripe-logo"
            style={{ marginTop: 0 }}
          />
        )}
      </Fragment>
    );
  };

  render() {
    if (this.props.isModal) return <this.PaymentFormContent />;
    return (
      <this.ScreenContainer>
        <this.PaymentFormContent />
      </this.ScreenContainer>
    );
  }
}

const Injected = injectStripe(PaymentSetupScreen);

const Wrapper = ({ serverProps: { stripeApiKey, ...props }, isModal = false, handleCancel }) => (
  <StripeProvider apiKey={stripeApiKey}>
    <Elements>
      <Injected {...props} isModal={isModal} handleCancel={handleCancel} />
    </Elements>
  </StripeProvider>
);

PaymentSetupScreen.propTypes = {
  customerEmail: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  stripe: PropTypes.shape({
    createSource: PropTypes.func.isRequired,
  }).isRequired,
};

PaymentSetupScreen.defaultProps = {
  isEdit: false,
};

Wrapper.propTypes = {
  serverProps: PropTypes.shape({
    stripeApiKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default Wrapper;
