import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager, NotificationContainer } from 'react-notifications';

import { changeSubscription, cancelSubscription } from '../services/api';

import SetupHeader from '../Components/SetupHeader';
import SubscriptionCard from '../Components/SubscriptionCard';

import styles from './Styles/SubscriptionsScreenStyles';
import { Metrics } from '../Themes';
import BackButton from '../Components/BackButton';

class EditSubscriptionScreen extends Component {
  static propTypes = {
    subscription: PropTypes.object,
    plans: PropTypes.object,
  };

  state = {
    addonCount: 0,
    hasUpdateRequest: false,
    updateMessage: '',
  };

  componentDidMount = () => {
    const {
      plans: { premium },
      subscription: {
        object_data: { plan_type: subscriptionType },
        studio_counts: { location_count: locationCount },
      },
    } = this.props;
    if (subscriptionType === 'premium') {
      const maxPremiumLocations = parseInt(premium[0].metadata.max_locations, 10);
      const addonCount =
        locationCount > maxPremiumLocations ? locationCount - maxPremiumLocations : 0;

      this.setState({ addonCount });
    }
  };

  getTotalCost = subscriptionType => {
    const { plans } = this.props;
    const { addonCount } = this.state;

    const basicPlan = plans.basic[0];
    const premiumPlan = plans.premium[0];
    const addonPlan = plans.premium[1];

    let planTotal = 0;

    if (subscriptionType === 'basic') {
      planTotal = basicPlan.amount / 100;
    } else if (subscriptionType === 'premium') {
      planTotal = (premiumPlan.amount + addonPlan.amount * addonCount) / 100;
    }

    return planTotal;
  };

  submitChangeSubscription = subscriptionType => {
    changeSubscription(subscriptionType).then(response => this.handleResponse(response));
  };

  submitDestroySubscription = () => {
    cancelSubscription().then(response => this.handleResponse(response));
  };

  handleResponse = response => {
    if (response.ok) {
      this.setState({ hasUpdateRequest: true, updateMessage: response.message });
    } else {
      NotificationManager.error(response.message);
    }
  };

  render() {
    const {
      subscription: {
        object_data: { plan_type: subscriptionType },
        studio_counts: studioCounts,
      },
      plans,
    } = this.props;
    const { hasUpdateRequest, updateMessage } = this.state;

    const basicPlan = plans.basic[0];
    const premiumPlan = plans.premium[0];

    const isBasic = subscriptionType === 'basic';
    const currentPlan = isBasic ? basicPlan : premiumPlan;
    const otherPlan = isBasic ? premiumPlan : basicPlan;
    const otherType = isBasic ? 'premium' : 'basic';
    const changeText = isBasic ? 'Upgrade To' : 'Downgrade To';

    return (
      <Fragment>
        <div className="screen-background">
          <BackButton />
          <div className="main-container no-padding">
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            {!hasUpdateRequest ? (
              <div
                style={styles.subscriptionCardsContainer}
                className="subscription-cards-container"
              >
                <SubscriptionCard
                  buttonActionText={'Cancel'}
                  editCardTitle={'Current Subscription'}
                  getTotalCost={this.getTotalCost}
                  hasButton={true}
                  hasDestructiveButton={true}
                  plans={plans}
                  studioCounts={studioCounts}
                  subscription={currentPlan}
                  subscriptionType={subscriptionType}
                  submitSubscription={this.submitDestroySubscription}
                  showCancel={true}
                />
                <div style={{ marginBottom: Metrics.section }} className="mobile-dividing-space" />
                <SubscriptionCard
                  buttonActionText={changeText}
                  editCardTitle={'Change Subscription'}
                  getTotalCost={this.getTotalCost}
                  hasButton={true}
                  plans={plans}
                  studioCounts={studioCounts}
                  subscription={otherPlan}
                  subscriptionType={otherType}
                  submitSubscription={this.submitChangeSubscription}
                  showCancel={false}
                  isEdit={true}
                />
              </div>
            ) : (
              <div style={styles.headerTextWrapper}>
                <p style={styles.headerMainText}>{`${updateMessage}.`}</p>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditSubscriptionScreen;
