import { formatDateTime } from './dateTime';

export const API_URLS = {
  dataAnalytics: ({ page = 1, limit = DEFAULTS.PAGINATION.LIMIT }, paginated = true) =>
    `/api/v1/analytics/classes/sub_requests/summary${
      paginated ? `?page=${page}&limit=${limit}` : ''
    }`,
  getClassNames: ({ studioId }) => `api/v1/studios/${studioId}/class_templates`,
  getInstructors: ({ studioId }) => `api/v1/studios/${studioId}/sub_requests/requesters`,
  getLocations: ({ studioId }) => `api/v1/studios/${studioId}/locations`,
};

export const DEFAULTS = {
  PAGINATION: {
    LIMIT: 50,
  },
};

export const DATE_FORMATS = {
  MMMM_dd_yyyy: 'MMMM DD, YYYY',
  MMMM_dd: 'MMMM DD',
  dd_MMM_yyyy: 'DD_MMM_YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
};

export const TIME_FORMATS = {
  '24_HOURS': 'HH:mm',
};

export const STATUS_CONSTANTS = {
  SUB_REQUEST: {
    requested: 'Requested',
    claimed: 'Claimed',
    accepted: 'Accepted',
    approved: 'Approved',
    completed: 'Completed',
    canceled: 'Cancelled',
    expired: 'Expired',
  },
};

export const SORTING_ORDERS = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const FILE_NAMES = {
  dataAnalyticsReport: () =>
    `data_analytics_${formatDateTime(new Date(), DATE_FORMATS.dd_MMM_yyyy)}.CSV`,
};

export const SUBSCRIPTION_TYPES = {
  BASIC: 'basic',
  PREMIUM: 'premium',
};

export const STEPS = {
  ACCOUNT_CREATION: ['Create Account', 'Select Plan', 'Credit Card', 'Connect & Sync', 'Summary'],
};

export const TABLE_COLUMNS = {
  DATA_ANALYTICS: [
    {
      width: 200,
      title: 'Class Name',
      dataIndex: 'className',
      key: 'class_name',
      fixed: 'left',
      sorter: true,
    },
    {
      width: 200,
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'class_date_time',
      fixed: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      width: 120,
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'class_time',
      fixed: 'left',
    },
    {
      width: 150,
      title: 'Location',
      dataIndex: 'locationName',
      key: 'location_name',
      sorter: true,
    },
    {
      width: 200,
      title: 'Req. by',
      dataIndex: 'requestedBy',
      key: 'requested_by',
      sorter: true,
    },
    {
      width: 200,
      title: 'Req. Date',
      dataIndex: 'requestedDate',
      key: 'requested_date_time',
      sorter: true,
    },
    {
      width: 200,
      title: 'Accepted Date',
      dataIndex: 'acceptedDate',
      key: 'accepted_date_time',
      sorter: true,
    },
    {
      width: 200,
      title: 'Accepted By',
      dataIndex: 'acceptedBy',
      key: 'accepted_by',
      sorter: true,
    },
    {
      width: 150,
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comment',
    },
  ],
};

export const URLS = {
  ACCOUNT_SETUP_INSTRUCTOR: 'https://instruktapp.com/account_setup_inst/',
  ACCOUNT_SETUP_OWNER: 'https://instruktapp.com/account_setup_owner/',
};
