import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import PaymentSetupScreen from './PaymentSetupScreen'

class EditPaymentScreen extends Component {
  render() {
    return (
      <PaymentSetupScreen serverProps={this.props.serverProps}/>
    )
  }
}

EditPaymentScreen.propTypes = {
  serverProps: PropTypes.shape({
    stripeApiKey: PropTypes.string.isRequired,
  }).isRequired,
}

export default EditPaymentScreen
