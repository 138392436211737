import { Metrics, Colors } from '../../Themes'

export default {
  subscriptionCardsContainer: {
    margin: '0 auto',
  },
  verticalDivider: {
    borderLeftColor: Colors.black15,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    height: '70%',
    margin: Metrics.section,
  },
}
