import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { NotificationManager, NotificationContainer } from 'react-notifications';

import { login } from '../services/api';

import SetupHeader from '../Components/SetupHeader';
import PrimaryButton from '../Components/PrimaryButton';
import LinkButton from '../Components/LinkButton';

import styles from './Styles/CreateResourceScreenStyles';

class LogInScreen extends Component {
  state = {
    email: '',
    password: '',
  };

  isAbsoluteURL = (url = '') => url.includes('https://instruktapp.com');

  goToForgotPassword = () => (window.location.pathname = '/users/password/new');

  goToLanding = () => (window.location.pathname = '/');

  handleResponse = response => {
    if (response.ok) {
      if (this.isAbsoluteURL(response.location)) window.location.replace(response.location);
      else window.location.pathname = response.location;
    } else {
      NotificationManager.error(response.message);
    }
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    if (this.validate()) {
      login({ email, password }).then(response => this.handleResponse(response));
    }
  };

  validate = () => {
    if (_.some(this.state, attr => attr === '')) {
      NotificationManager.error('Missing required fields');
    } else {
      return true;
    }
  };

  updateInput = (input, attr) => this.setState({ [attr]: input.target.value });

  render() {
    const { email, password } = this.state;

    return (
      <Fragment>
        <div className="screen-background">
          <form className="main-container" onSubmit={this.handleSubmit}>
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            <div style={styles.headerTextWrapper}>
              <p style={styles.headerMainText}>Log In</p>
            </div>
            <label className="input-label" style={styles.label}>
              <input
                placeholder="Email"
                style={styles.input}
                type="text"
                value={email}
                onChange={input => this.updateInput(input, 'email')}
                autoComplete="email"
              />
            </label>
            <label className="input-label" style={styles.bottomLabel}>
              <input
                placeholder="Password"
                style={styles.input}
                type="password"
                value={password}
                onChange={input => this.updateInput(input, 'password')}
                autoComplete="password"
              />
            </label>
            <div style={styles.buttonsContainer}>
              <PrimaryButton onPress={this.handleSubmit} text="Log In" width="24vw" newCtaButton />
            </div>
            <div style={styles.linksContainer}>
              <LinkButton linkAction={this.goToLanding} linkText={'New to Instrukt?'} />
              <LinkButton linkAction={this.goToForgotPassword} linkText={'Forgot Password?'} />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default LogInScreen;
