import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { NotificationManager, NotificationContainer } from 'react-notifications'

import { syncStudio } from '../services/api'

import SetupHeader from '../Components/SetupHeader'
import PrimaryButton from '../Components/PrimaryButton'

import styles from './Styles/CreateResourceScreenStyles'
import { Colors } from '../Themes'

class SyncStudioScreen extends Component {
  handlePressSync = () => {
    const { studio } = this.props
    syncStudio(studio).then(response => this.handleSyncResponse(response))
  }

  handleSyncResponse = response => {
    if (response.ok) {
      window.location.pathname = '/subscriptions/new'
    } else {
      NotificationManager.error(response.message)
    }
  }

  handlePressDownload = () => {
    window.location.pathname = '/download_link'
  }

  handlePressEdit = () => {
    const { studio } = this.props
    window.location.href = `/studios/${studio.id}/edit`
  }

  renderSyncingText = () => (
    <Fragment>
      <p style={styles.boldText}>
        Instrukt is syncing with Mindbody. This may take a several minutes.
      </p>
      <p style={styles.sectionText}>
        Have you downloaded the app? Click "Download Instrukt" to get it.
      </p>
      <p style={styles.sectionText}>
        When you have the app installed on your mobile device, you will see your studio data as soon
        as our sync completes. <a>Open the app</a>.
      </p>
    </Fragment>
  )

  render() {
    return (
      <Fragment>
        <NotificationContainer />
        <div className="screen-background">
          <div className="main-container">
            <SetupHeader />
            <div style={styles.headerTextWrapper}>
              <p style={styles.headerMainText}>Sync Instrukt With Your Mindbody Account</p>
            </div>
            <div style={styles.infoContainer}>
              <p style={styles.infoText}>
                Success! Now press <strong>Sync Your Account</strong> to sync your Instrukt studio
                with your Site in Mindbody.
              </p>
            </div>
            <div style={styles.buttonsContainer} className="buttons-container">
              <PrimaryButton
                onPress={this.handlePressSync}
                text="Sync Your Account"
                color={Colors.primaryThemeLight}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

SyncStudioScreen.propTypes = {
  studio: PropTypes.object,
}

export default SyncStudioScreen
