import React, { Component, Fragment } from 'react'
import { NotificationManager, NotificationContainer } from 'react-notifications'

import { createPasswordReset } from '../services/api'

import SetupHeader from '../Components/SetupHeader'
import PrimaryButton from '../Components/PrimaryButton'
import LinkButton from '../Components/LinkButton'

import styles from './Styles/CreateResourceScreenStyles'

class ForgotPasswordScreen extends Component {
  state = {
    email: '',
    success: false,
  }

  updateInput = (input, attr) => this.setState({ [attr]: input.target.value })

  handleSubmit = () => {
    const { email } = this.state
    if (this.validate()) {
      createPasswordReset(email).then(response => this.handleResponse(response))
    }
  }

  validate = () => {
    if (this.state.email === '') {
      NotificationManager.error('Missing required fields')
    } else {
      return true
    }
  }

  handleResponse = response => {
    if (response.ok) {
      NotificationManager.success('Success!')
      this.setState({ success: true })
    } else {
      NotificationManager.error('Email not found')
    }
  }

  goToSignIn = () => (window.location.pathname = '/users/sign_in')
  goToSignUp = () => (window.location.href = '/')

  render() {
    const { email, success } = this.state
    const instructions = success
      ? `Your request has been received. Please follow the instructions in the email sent to ${email}.`
      : 'Please enter the email address you used to register your account.'

    return (
      <Fragment>
        <NotificationContainer />
        <div className="screen-background">
          <form className="main-container" onSubmit={this.handleSubmit}>
            <SetupHeader />
            <div style={styles.headerTextWrapper}>
              <p style={styles.headerMainText}>Reset Your Password</p>
            </div>
            <div style={styles.infoContainer}>
              <p style={styles.infoText}>{instructions}</p>
            </div>
            <label className="input-label" style={styles.bottomLabel}>
              <input
                placeholder="Email"
                style={styles.input}
                type="text"
                value={email}
                onChange={input => this.updateInput(input, 'email')}
                autoComplete="email"
              />
            </label>
            <div style={styles.buttonsContainer} className="buttons-container">
              <PrimaryButton onPress={this.handleSubmit} text="Submit" />
            </div>
            <div style={styles.linksContainer}>
              <LinkButton linkAction={this.goToSignUp} linkText={'New to Instrukt?'} />
              <LinkButton linkAction={this.goToSignIn} linkText={'Sign In'} />
            </div>
          </form>
        </div>
      </Fragment>
    )
  }
}

export default ForgotPasswordScreen
