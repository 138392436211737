import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import styles from './Styles/LinkButtonStyles';

const LinkButton = ({ linkAction, linkText = '', loading = false, linkStyle = {} }) => (
  <div className="LinkButton" style={styles.linkContainer} onClick={linkAction}>
    {loading ? (
      <Spin indicator={<Icon type="loading" spin />} />
    ) : (
      <p style={{ ...styles.linkText, ...linkStyle }}>{linkText}</p>
    )}
  </div>
);

LinkButton.propTypes = {
  linkAction: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default LinkButton;
