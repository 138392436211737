import React, { Component, Fragment } from 'react';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import _ from 'lodash';

import { createStudio } from '../services/api';
import { emptyFieldValidation } from '../utils/inputValidation';

import SetupHeader from '../Components/SetupHeader';
import StudioForm from '../Components/StudioForm';

import styles from './Styles/CreateResourceScreenStyles';
import Stepper from '../Components/Stepper';
import { STEPS } from '../utils/constants';
import BackButton from '../Components/BackButton';

class CreateStudioScreen extends Component {
  state = {
    errorFields: [],
    mb_site_id: '',
    name: '',
  };

  updateInput = (input, attr) => this.setState({ [attr]: input.target.value });

  handleSubmit = () => {
    const { name, mb_site_id } = this.state;
    if (this.validate({ name, mb_site_id })) {
      createStudio({ name, mb_site_id }).then(response => this.handleResponse(response));
    }
  };

  validate = fields => {
    const errorFields = emptyFieldValidation(fields);

    if (errorFields.length !== 0) {
      NotificationManager.error('Missing required fields');
      this.setState({ errorFields });
    } else {
      return true;
    }
  };

  handleResponse = response => {
    if (response.ok) {
      window.location.href = '/studios/activate';
    } else {
      NotificationManager.error(response.message);
    }
  };

  render() {
    const { mb_site_id, name, errorFields } = this.state;

    return (
      <Fragment>
        <div className="screen-background">
          <BackButton />
          <div className="main-container no-padding">
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            <Stepper steps={STEPS.ACCOUNT_CREATION} current={3} className="account-stepper" />
            <div className="connect-studio-form-container">
              <div style={styles.headerTextWrapper}>
                <p style={styles.headerMainText}>
                  Activate the connection with your Mindbody account
                </p>
              </div>
              <StudioForm
                errorFields={errorFields}
                name={name}
                mbSiteId={mb_site_id}
                handleSubmit={this.handleSubmit}
                updateInput={this.updateInput}
                submitText="Get Activation Code"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CreateStudioScreen;
