import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import SetupHeader from '../Components/SetupHeader';
import SettingsLinkItem from '../Components/SettingsLinkItem';

import styles from './Styles/CreateResourceScreenStyles';

const AccountScreen = ({ plan_type, card }) => {
  const updateSubscription = () => (window.location.pathname = 'subscriptions/edit');
  const updateCreditCard = () => (window.location.pathname = 'stripe_sources/edit');
  const viewDataAnalytics = () => (window.location.pathname = 'data_analytics');

  return (
    <div className="screen-background">
      <div className="main-container">
        <SetupHeader />
        <div style={styles.headerTextWrapper}>
          <p style={styles.headerMainText}>Account Settings</p>
        </div>
        <div style={styles.accountLinksContainer}>
          <SettingsLinkItem
            linkAction={updateSubscription}
            linkHeader="Current Subscription:"
            linkInfo={`${plan_type} Plan`}
          />
          <SettingsLinkItem
            linkAction={updateCreditCard}
            linkHeader="Current Card:"
            linkInfo={`${card.brand}, XXXX-XXXX-XXXX-${card.last4}`}
          />
          <SettingsLinkItem
            linkAction={viewDataAnalytics}
            linkHeader="Sub Reports"
            actionItem={
              <span style={{ color: 'black' }}>
                <Icon type="right" style={{ fontSize: 18 }} />
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

AccountScreen.propTypes = {
  plan_type: PropTypes.string,
  card: PropTypes.object,
};

export default AccountScreen;
