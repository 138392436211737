import CreateResourceScreenStyles from './CreateResourceScreenStyles'
import { Colors, Fonts, Metrics } from '../../Themes'

export default {
  ...CreateResourceScreenStyles,
  stripeElement: {
    base: {
      color: Colors.black50,
      fontSize: '17px',
      fontWeight: Fonts.weight.regular,
      letterSpacing: -0.4,
    },
  },
  stripeElementLabel: {
    paddingBottom: Metrics.baseMargin + Metrics.smallMargin,
    marginBottom: Metrics.section,
    paddingTop: Metrics.baseMargin + Metrics.smallMargin,
    minWidth: '100%',
    paddingLeft: Metrics.doubleBaseMargin,
  },
  stateSelectionLabel: {
    color: Colors.black22,
    fontSize: Fonts.size.medium,
    marginBottom: Metrics.fatMargin,
    minWidth: '100%',
  },
  columnHeaderText: {
    color: Colors.black75,
    fontSize: Fonts.size.headerText,
    fontWeight: Fonts.weight.light,
    letterSpacing: 0.3,
  },
  addressWrapper: {
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: Metrics.doubleBaseMargin,
  },
}
