import { Colors } from '../Themes'

export const emptyFieldValidation = fields =>
  Object.keys(fields).filter(field => {
    return fields[field] === ''
  })

export const getLabelStyle = (errorFields, fieldName, originalStyle) => ({
  ...originalStyle,
  ...{ borderBottomColor: hasError(errorFields, fieldName) ? Colors.error : null },
})

export const hasError = (errorFields, fieldName) => errorFields && errorFields.includes(fieldName)

export const validateEmail = email => {
  const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
  return emailPattern.test(String(email).toLowerCase())
}
