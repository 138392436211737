import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import ReactPhoneInput from 'react-phone-input-2'

import { Colors, Fonts } from '../Themes'

import styles from '../Screens/Styles/CreateResourceScreenStyles'

class PhoneInput extends Component {
  state = {
    showInput: false,
  }

  handleClick = () => this.setState({ showInput: true })

  render() {
    const { handleInputUpdate, phoneNumber } = this.props
    const { showInput } = this.state

    const inputStyle = {
      border: 'none',
      boxShadow: 'none',
      color: Colors.black50,
      fontSize: Fonts.size.regular,
      fontWeight: Fonts.weight.regular,
      letterSpacing: -0.4,
      lineHeight: 1.2,
      width: '100%',
    }

    return (
      <Fragment>
        {showInput ? (
          <ReactPhoneInput
            buttonStyle={{ backgroundColor: 'transparent', border: 'none', paddingLeft: '8px' }}
            containerStyle={{ marginBottom: '8px' }}
            country='us'
            dropdownClass={'phone-input-country-list'}
            inputExtraProps={{ autoFocus: showInput }}
            inputStyle={inputStyle}
            onChange={(input) => handleInputUpdate(`+${input}`)}
            placeholder={'Phone Number'}
            preferredCountries={['us']}
            value={phoneNumber}
          />
        ) : (
          <div style={{ ...styles.input, paddingTop: '8px' }} onClick={this.handleClick}>
            Phone Number
          </div>
        )}
      </Fragment>
    )
  }
}

PhoneInput.propTypes = {
  handleInputUpdate: PropTypes.func,
  phoneNumber: PropTypes.string,
}

export default PhoneInput
