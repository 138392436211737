const metrics = {
  touchTarget: 44,
  thinBorder: 0.5,
  marginHorizontal: 8,
  marginVertical: 8,
  section: 24,
  baseMargin: 8,
  doubleBaseMargin: 16,
  tripleBaseMargin: 24,
  fatMargin: 40,
  smallMargin: 4,
  doubleSection: 48,
  horizontalLineHeight: 1,
  footerBase: 33,
  buttonRadius: 4,
  calendarItem: 80,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
    headerIcon: 20,
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 200,
  },
}

export default metrics
