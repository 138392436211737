import React, { Component, Fragment } from 'react'
import { isValidNumber } from 'libphonenumber-js'

import { sendDownloadLink } from '../services/api'

import { NotificationManager } from 'react-notifications'
import PhoneInput from './PhoneInput'
import PrimaryButton from './PrimaryButton'

import styles from '../Screens/Styles/CreateResourceScreenStyles'

import { Colors } from '../Themes'

class DownloadLinkForm extends Component {
  state = { phoneNumber: '', errorMessage: null }

  handleSubmit = () => {
    this.setState({ errorMessage: null })
    const { phoneNumber } = this.state
    if (this.validateNumber()) {
      sendDownloadLink(phoneNumber).then(response => this.handleResponse(response))
    }
  }

  validateNumber = () => {
    const { phoneNumber } = this.state
    if (phoneNumber === '') {
      NotificationManager.error('Mobile number cannot be blank')
      this.setState({ errorMessage: 'Cannot be blank' })
    } else if (!isValidNumber(phoneNumber)) {
      NotificationManager.error('Mobile number is not valid')
      this.setState({ errorMessage: 'Is not valid' })
    } else {
      return true
    }
  }

  handleResponse = response => {
    const { ok, mobile_number } = response
    if (ok) {
      NotificationManager.success(`A link was sent to ${mobile_number}`)
    } else {
      NotificationManager.error('Something went wrong. Check the number or try again later')
    }
  }

  handleInputUpdate = phoneNumber => this.setState({ phoneNumber })

  render() {
    const { phoneNumber, errorMessage } = this.state

    return (
      <Fragment>
        <div style={styles.infoContainer}>
          <p style={styles.infoText}>
            Enter Your Mobile Number To Receive A Link To Download Instrukt
          </p>
        </div>
        <div
          className="input-label"
          style={{ ...styles.bottomLabel, borderBottomColor: errorMessage ? Colors.error : null }}
        >
          <PhoneInput
            handleInputUpdate={value => this.handleInputUpdate(value)}
            phoneNumber={phoneNumber}
          />
        </div>
        <div style={styles.buttonsContainer} className="buttons-container">
          <PrimaryButton text="Submit" onPress={this.handleSubmit} />
        </div>
      </Fragment>
    )
  }
}

export default DownloadLinkForm
