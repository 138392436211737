import { Colors, Fonts, Metrics } from '../../Themes'

export default {
  baseButton: {
    alignItems: 'center',
    borderRadius: Metrics.baseMargin,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    margin: Metrics.smallMargin,
  },
  primaryButton: {
    backgroundColor: Colors.primaryTheme,
  },
  destructiveButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.destroyWarningRed}`,
  },
  disabledButton: {
    backgroundColor: Colors.frost,
  },
  baseText: {
    fontWeight: Fonts.weight.semibold,
    fontSize: Fonts.size.regular,
    lineHeight: 1.3,
    padding: Metrics.doubleBaseMargin,
    textAlign: 'center',
  },
  primaryText: {
    color: Colors.snow,
  },
  destructiveText: {
    color: Colors.destroyWarningRed,
  },
}
