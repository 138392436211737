import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

export default ({ steps = [], current = 0, className = '' }) => (
  <Steps size="small" current={current} className={className}>
    {steps.map(step => (
      <Step title={step} />
    ))}
  </Steps>
);
