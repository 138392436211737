import { notification } from 'antd';

export default {
  error: config =>
    notification.error({
      placement: 'bottomRight',
      message: 'Something unexpected happened',
      duration: 3,
      bottom: 5,
      style: {
        right: -100,
        textTransform: 'capitalize',
      },
      ...config,
    }),
};
