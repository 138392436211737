import React from 'react';
import PropTypes from 'prop-types';

import styles from './Styles/PrimaryButtonStyles';

const PrimaryButton = ({
  color,
  isDestructive,
  isDisabled,
  onPress,
  text,
  textColor,
  block = false,
  newCtaButton = false,
  style,
}) => {
  let buttonStyle = {};
  color
    ? (buttonStyle = { ...styles.baseButton, backgroundColor: color })
    : (buttonStyle = { ...styles.baseButton, ...styles.primaryButton });

  isDestructive
    ? (buttonStyle = { ...styles.baseButton, ...styles.destructiveButton })
    : buttonStyle;

  isDisabled ? (buttonStyle = { ...styles.baseButton, ...styles.disabledButton }) : buttonStyle;

  block ? (buttonStyle = { ...buttonStyle, width: '100%' }) : buttonStyle;

  newCtaButton
    ? (buttonStyle = {
        ...buttonStyle,
        height: 40,
        borderRadius: 4,
      })
    : buttonStyle;

  style
    ? (buttonStyle = {
        ...buttonStyle,
        ...style,
      })
    : buttonStyle;

  let textStyle = isDestructive
    ? { ...styles.baseText, ...styles.destructiveText }
    : textColor
      ? { ...styles.baseText, color: textColor }
      : { ...styles.baseText, ...styles.primaryText };
    
    newCtaButton ? (textStyle = {
      ...textStyle,
      fontSize: 14,
      fontWeight: 400,
    }) : textStyle;

  return (
    <div className="primary-button" style={buttonStyle} onClick={!isDisabled ? onPress : undefined}>
      <p style={textStyle}>{text}</p>
    </div>
  );
};

PrimaryButton.defaultProps = {
  isDisabled: false,
};

PrimaryButton.propTypes = {
  color: PropTypes.string,
  isDestructive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onPress: PropTypes.func,
  text: PropTypes.string,
  textColor: PropTypes.string,
};

export default PrimaryButton;
