import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'antd';

import { SUBSCRIPTION_TYPES } from '../utils/constants';
import Tooltip from './AntTooltip';

const SubscriptionCard = ({
  plans,
  subscription,
  submitSubscription,
  subscriptionType,
  loading = '',
  showCancel = false,
  isEdit = false,
}) => {
  const metadata = subscription.metadata;
  const subscriptionTitle = metadata.plan_title || metadata.plan_name;

  // plans
  const basicPlan = plans.basic[0];
  const premiumPlan = plans.premium[0];
  const addonPlan = plans.premium[1];

  // plan variables
  const addonCost = addonPlan.amount / 100;
  const maxBasicInstructors = basicPlan.metadata.max_instructors;
  const maxPremiumLocations = premiumPlan.metadata.max_locations;
  const subscriptionTotal = getTotalCost(subscriptionType);

  function getTotalCost(subscriptionType) {
    let planTotal = 0;

    if (subscriptionType === 'basic') {
      planTotal = basicPlan.amount / 100;
    } else if (subscriptionType === 'premium') {
      planTotal = (premiumPlan.amount) / 100;
    }

    return planTotal;
  }

  const upgradeTitle =
    subscriptionType === SUBSCRIPTION_TYPES.PREMIUM
      ? `Upgrade to ${subscriptionTitle}`
      : `Downgrade to ${subscriptionTitle}`;

  // tooltip text variables
  const addonText = () => (
    <div style={{ flexDirection: 'column' }}>
      <div>
        The Premium includes {maxPremiumLocations} location. You will be billed ${addonCost} for
        each additional location.
      </div>
      <div>All locations must be under the same Mindbody siteID.</div>
      <div>If you have multiple siteIDs a separate Instrukt subscription is required for each.</div>
    </div>
  );

  const maxBasicInstructorsWarning = () => (
    <div>
      <div>
        The Basic plan allows for a total of {maxBasicInstructors} instructors to be active in
        Instrukt at a time.
      </div>
      <div>
        If you activate more than {maxBasicInstructors} instructors you will need to upgrade to the
        Premium plan, or if you plan on activating more than {maxBasicInstructors} instructors you
        may choose the Premium plan now.
      </div>
      <div />
    </div>
  );

  const StudioSupport = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.BASIC)
      return <div>For studios with only {subscription.metadata.max_locations} location</div>;
    else if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return (
        <div>
          {subscription.metadata.max_locations} studio location included
          <Tooltip title={addonText} />
        </div>
      );
  };

  const ActiveInstructors = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.BASIC)
      return (
        <div>
          Active Instructors: Up to {subscription.metadata.max_instructors}
          <Tooltip title={maxBasicInstructorsWarning} />
        </div>
      );
    else if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return <div>Active Instructors: {subscription.metadata.max_instructors}</div>;
  };

  const AddonCost = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return (
        <div>
          ${addonCost} Additional Locations
          <Tooltip title={addonText} />
        </div>
      );
    return null;
  };

  const LocationSuppport = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return <div>Support for Multiple Locations Under one MINDBODY Site ID</div>;
    return null;
  };

  const SelectSubscriptionButton = () => (
    <Button
      size="large"
      loading={subscriptionType === loading}
      disabled={loading && subscriptionType !== loading}
      onClick={() => submitSubscription(subscriptionType)}
      style={{
        margin: '32px 0',
      }}
      {...(showCancel
        ? { type: 'danger', ghost: true }
        : subscriptionType === SUBSCRIPTION_TYPES.PREMIUM
          ? { type: 'primary' }
          : { type: 'primary', ghost: true })}
    >
      {showCancel ? 'Cancel Subscription' : isEdit ? upgradeTitle : `Select ${subscriptionTitle}`}
    </Button>
  );

  return (
    <div className="subscription-card-container">
      <div className="card-container-header">
        <h3>
          {subscriptionTitle} ${subscriptionTotal}
        </h3>
        <div>per month</div>
      </div>
      <Card
        title={
          <Fragment>
            <div className="header-title">Plan Total: </div>
            <div>${subscriptionTotal} Plan Price</div>
            <AddonCost />
          </Fragment>
        }
        className="subscription-card"
        style={{ width: 280, height: 'calc(100% - 112px)' }}
        bordered={false}
      >
        <div>{subscription.metadata.trial_period} day FREE trial</div>
        <div>Integration with one MINDBODY site ID</div>
        <LocationSuppport />
        <StudioSupport />
        <ActiveInstructors />
      </Card>
      <SelectSubscriptionButton />
    </div>
  );
};

SubscriptionCard.propTypes = {
  baseQualifiedPlan: PropTypes.string,
  buttonActionText: PropTypes.string,
  editCardTitle: PropTypes.string,
  getTotalCost: PropTypes.func,
  hasButton: PropTypes.bool,
  hasDestructiveButton: PropTypes.bool,
  plans: PropTypes.object,
  studioCounts: PropTypes.object,
  submitSubscription: PropTypes.func,
  subscription: PropTypes.object.isRequired,
  subscriptionType: PropTypes.string.isRequired,
};

SubscriptionCard.defaultProps = {
  buttonActionText: 'Select',
};

export default SubscriptionCard;
