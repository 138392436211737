import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager, NotificationContainer } from 'react-notifications';

import { addSubscription } from '../services/api';

import SetupHeader from '../Components/SetupHeader';
import SubscriptionContainer from '../Components/SubscriptionsContainer';

import styles from './Styles/SubscriptionsScreenStyles';
import Stepper from '../Components/Stepper';
import { STEPS } from '../utils/constants';
import BackButton from '../Components/BackButton';

class SubscriptionScreen extends Component {
  state = {
    addonCount: 0,
    disableButton: false,
    loading: '',
  };

  handleResponse = response => {
    if (response.ok) {
      window.location.href = response.redirect_url;
    } else {
      NotificationManager.error(response.message);
    }
  };

  submitSubscription = subscriptionType => {
    this.setState({ loading: subscriptionType }, () =>
      addSubscription(subscriptionType).then(response => {
        this.handleResponse(response);
        this.setState({ loading: '' });
      })
    );
  };

  render() {
    const { plans } = this.props;

    const { loading } = this.state;

    const subscriptionHeaderText = `Invest in efficiency and do more of what matters. Get your sub requests filled faster.`;

    return (
      <Fragment>
        <div className="screen-background">
          <BackButton />
          <div className="main-container no-padding">
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            <Stepper steps={STEPS.ACCOUNT_CREATION} current={1} className="account-stepper" />
            <div style={{ ...styles.headerTextWrapper, marginBottom: 4 }}>
              <p style={styles.headerMainText}>Select Your Subscription</p>
            </div>
            <div style={styles.infoContainer}>
              <p style={{ ...styles.infoText, fontSize: 14 }} className="info-text">
                {subscriptionHeaderText}
              </p>
            </div>
            <SubscriptionContainer
              plans={plans}
              submitSubscription={this.submitSubscription}
              loading={loading}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

SubscriptionScreen.propTypes = {
  plans: PropTypes.object,
  subscriptions: PropTypes.object,
};

export default SubscriptionScreen;
