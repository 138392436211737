import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { NotificationManager, NotificationContainer } from 'react-notifications'

import { updatePassword } from '../services/api'

import SetupHeader from '../Components/SetupHeader'
import PrimaryButton from '../Components/PrimaryButton'

import styles from './Styles/CreateResourceScreenStyles'

class UpdatePasswordScreen extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    resetPasswordToken: null,
    resetSuccess: false,
  }

  componentDidMount = () => {
    const resetPasswordToken = this.props.reset_password_token
    this.setState({ resetPasswordToken })
  }

  goToWelcome = () => {
    window.location.pathname = '/pages/welcome'
  }

  goToSignIn = () => (window.location.pathname = '/users/sign_in')
  goToSignUp = () => (window.location.href = '/users/sign_up')

  handleResponse = response => {
    if (response.ok) {
      NotificationManager.success('Success!')
      this.setState({ resetSuccess: true })
    } else {
      NotificationManager.error(response.message)
    }
  }

  handleSubmit = () => {
    const params = this.getParams()
    if (this.validate()) {
      updatePassword(params).then(response => this.handleResponse(response))
    }
  }

  updateInput = (input, attr) => this.setState({ [attr]: input.target.value })

  validate = () => {
    if (this.state.newPassword === '' || this.state.confirmPassword === '') {
      NotificationManager.error('Fields cannont be empty')
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      NotificationManager.error('Passwords must match')
    } else {
      return true
    }
  }

  getParams = () => ({
    password: this.state.newPassword,
    reset_password_token: this.state.resetPasswordToken,
  })

  render() {
    const { newPassword, confirmPassword, resetSuccess } = this.state

    const instructions = !resetSuccess
      ? `Enter your new Password and${'\n'}Password confirmation below.`
      : 'Your password has been successfully updated.'

    return (
      <Fragment>
        <NotificationContainer />
        <div className="screen-background">
          <form className="main-container" onSubmit={this.handleSubmit}>
            <SetupHeader />
            <div style={styles.headerTextWrapper}>
              <p style={styles.headerMainText}>Reset Your Password</p>
            </div>
            <div style={styles.infoContainer}>
              <p style={styles.infoText}>{instructions}</p>
            </div>
            {resetSuccess ? (
              <div style={styles.buttonsContainer}>
                <PrimaryButton onPress={this.goToWelcome} text="Continue" width="24vw" />
              </div>
            ) : (
              <Fragment>
                <label className="input-label" style={styles.label}>
                  <input
                    placeholder="Enter Your New Password"
                    style={styles.input}
                    type="password"
                    value={newPassword}
                    onChange={input => this.updateInput(input, 'newPassword')}
                  />
                </label>
                <label className="input-label" style={styles.bottomLabel}>
                  <input
                    placeholder="Confirm Your New Password"
                    style={styles.input}
                    type="password"
                    value={confirmPassword}
                    onChange={input => this.updateInput(input, 'confirmPassword')}
                  />
                </label>
                <div style={styles.buttonsContainer} className="buttons-container">
                  <PrimaryButton onPress={this.handleSubmit} text="Submit" />
                </div>
              </Fragment>
            )}
          </form>
        </div>
      </Fragment>
    )
  }
}

UpdatePasswordScreen.propTypes = {
  resetPasswordToken: PropTypes.string,
}

export default UpdatePasswordScreen
