import { API_URLS } from '../utils/constants';
import { post, get } from '../utils/http';
import { handleError } from '../utils';

export const fetchDataAnalyticsSummary = ({
  token = '',
  queryParams = '',
  payload = '',
  paginated = true,
}) => {
  const url = API_URLS.dataAnalytics(queryParams, paginated);
  return post({
    url,
    token,
    payload,
  }).catch(handleError);
};

export const fetchClassNames = ({ token = '', payload: { studioId = '' } }) => {
  const url = API_URLS.getClassNames({ studioId });
  return get({
    url,
    token,
  }).catch(handleError);
};

export const fetchInstructors = ({ token = '', payload: { studioId = '' } }) => {
  const url = API_URLS.getInstructors({ studioId });
  return get({
    url,
    token,
  }).catch(handleError);
};

export const fetchLocations = ({ token = '', payload: { studioId = '' } }) => {
  const url = API_URLS.getLocations({ studioId });
  return get({
    url,
    token,
  }).catch(handleError);
};
