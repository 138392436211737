import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import styles from './Styles/SettingsLinkItemStyles'

const SettingsLinkItem = ({ linkAction, linkHeader, linkInfo, actionItem = 'Update' }) => (
  <Fragment>
    <div style={styles.horizontalDivider} />
    <div className="account-link-wrapper">
      <div className="account-text-wrapper">
        <p style={styles.linkHeader}>{linkHeader}</p>
        <p className="empty-space">&nbsp;</p>
        <p style={styles.linkInfo}>{linkInfo}</p>
      </div>
      <p style={styles.updateLink} onClick={linkAction}>
        {actionItem}
      </p>
    </div>
  </Fragment>
)

SettingsLinkItem.propTypes = {
  linkAction: PropTypes.func,
  linkHeader: PropTypes.string,
  linkInfo: PropTypes.string,
  actionItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default SettingsLinkItem
