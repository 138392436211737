import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { syncStudio } from '../services/api';

import SetupHeader from '../Components/SetupHeader';
import PrimaryButton from '../Components/PrimaryButton';
import ToolTip from '../Components/ToolTip';
import ToolTipModal from '../Components/ToolTipModal';
import LinkButton from '../Components/LinkButton';
import { NotificationManager, NotificationContainer } from 'react-notifications';

import styles from './Styles/CreateResourceScreenStyles';
import { Colors, Metrics } from '../Themes';
import Stepper from '../Components/Stepper';
import { STEPS } from '../utils/constants';
import BackButton from '../Components/BackButton';

class ActivateStudioScreen extends Component {
  handlePressActivate = () => {
    const { studio } = this.props;
    window.open(studio.mb_activation_link, '_blank').focus();
  };

  handlePressBack = () => {
    const { studio } = this.props;
    window.location.href = `/studios/${studio.id}/edit`;
  };

  handlePressNext = () => {
    syncStudio().then(response => this.handleSyncResponse(response));
  };

  handleSyncResponse = response => {
    if (response.ok) {
      window.location.pathname = '/subscriptions/confirm';
    } else {
      NotificationManager.error(response.message);
    }
  };

  render() {
    const { studio } = this.props;

    const activateToolTipContent = `
      <p>You may manually integrate with your Mindbody account by copying</p>
      <p>and pasting the activation code into the API activation area</p>
      <p>on the Mindbody owner portal.</p>
    `;

    return (
      <div className="screen-background">
        <BackButton />
        <div className="activate-container">
          <div className="fix-align">
            <NotificationContainer />
          </div>
          <SetupHeader />
          <Stepper steps={STEPS.ACCOUNT_CREATION} current={3} className="account-stepper" />
          <div className="activate-studio-form-container">
            <div style={styles.headerTextWrapper}>
              <p style={{ ...styles.headerMainText, marginBottom: 28 }}>
                Activate the connection with your Mindbody account
              </p>
            </div>
            <div style={styles.activateCardsContainer} className="activate-card-container">
              <div style={styles.activateCard} className="activate-card">
                <p style={styles.activateCardHeader} className="activate-card-header">
                  1. Connect your Mindbody account to your Instrukt account
                </p>
                <div className="buttons-container" style={styles.buttonsContainer}>
                  <PrimaryButton
                    onPress={this.handlePressActivate}
                    text="Connect"
                    color={Colors.successGreen}
                    newCtaButton
                  />
                </div>
                <div style={styles.codeContainer} className="code-container">
                  <p style={styles.codeLabel}>Activation Code:</p>
                  <ToolTip
                    iconSize={Metrics.icons.headerIcon}
                    toolTipContent={activateToolTipContent}
                  />
                  <ToolTipModal
                    iconSize={Metrics.icons.headerIcon}
                    toolTipContent={activateToolTipContent}
                  />
                  <code style={styles.code}>{studio.mb_activation_code}</code>
                </div>
                <a
                  style={styles.activateLink}
                  href="https://support.mindbodyonline.com/s/article/Setting-up-an-API-integration"
                  target="_blank"
                >
                  Learn more about manual activation
                </a>
              </div>
              <div className="mobile-dividing-space" />
              <div style={styles.verticalDivider} />
              <div style={styles.activateCard} className="activate-card">
                <p style={styles.activateCardHeader} className="activate-card-header">
                  2. After you confirm the Mindbody connection was successful, return to this page
                  and press Sync
                </p>
                <div className="buttons-container" style={styles.buttonsContainer}>
                  <PrimaryButton
                    color={Colors.primaryTheme}
                    onPress={this.handlePressNext}
                    text="Sync"
                    newCtaButton
                  />
                </div>
              </div>
            </div>
            <div style={styles.dividingSpace} />
            <p style={{ ...styles.activateCardHeader, marginBottom: 0 }}>Trouble Activating?</p>
            <div style={styles.activateFooter} className="activate-footer">
              <LinkButton linkAction={this.handlePressBack} linkText={'Edit your studio'} />
              <p style={styles.infoText}>&nbsp;and get a new activation code</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ActivateStudioScreen.propTypes = {
  studio: PropTypes.object,
};

export default ActivateStudioScreen;
