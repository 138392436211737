import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { NotificationContainer } from 'react-notifications'

import SetupHeader from '../Components/SetupHeader'
import AppStoreBadge from '../Components/Icons/AppStoreBadge'
import DownloadLinkForm from '../Components/DownloadLinkForm'
import PlayStoreBadge from '../Images/play_store_badge.png'

import styles from './Styles/CreateResourceScreenStyles'

const DownloadsScreen = ({ url, is_mobile, is_ios }) => {
  const renderStoreBadge = () =>
    is_ios ? (
      <AppStoreBadge />
    ) : (
      <img
        className="badge-icon"
        src={PlayStoreBadge}
        alt="Instrukt is available on the Play Store."
      />
    )

  const handleDownloadLink = () => {
    window.location.href = url
  }

  return (
    <Fragment>
      <NotificationContainer />
      <div className="screen-background">
        <div className="main-container">
          <SetupHeader />
          <p style={styles.promoText}>
            SIMPLIFYING
            <br />
            STUDIO
            <br />
            MANAGEMENT
          </p>
          {is_mobile ? (
            <div onClick={handleDownloadLink}>{renderStoreBadge()}</div>
          ) : (
            <DownloadLinkForm />
          )}
        </div>
      </div>
    </Fragment>
  )
}

DownloadsScreen.propTypes = {
  url: PropTypes.string,
  is_mobile: PropTypes.bool,
  is_ios: PropTypes.bool,
}

export default DownloadsScreen
