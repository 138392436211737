import { Colors, Fonts, Metrics } from '../../Themes'

export default {
  linkContainer: {
    cursor: 'pointer',
    padding: Metrics.smallMargin,
  },
  linkText: {
    color: Colors.primaryTheme,
    fontSize: Fonts.size.regular,
    fontWeight: Fonts.weight.light,
  },
}
