const colors = {
  background: '#FAFAFA',
  black10: 'rgba(0, 0, 0, 0.1)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black22: 'rgba(0, 0, 0, 0.22)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black100: 'rgba(0, 0, 0, 1)',
  paymentBorder: '#b1b1b1',
  charcoal: '#595959',
  clear: 'rgba(0,0,0,0)',
  darkBlue: '#1864ab',
  darkText: '#3F3F3F',
  destroyWarningRed: '#fa5252',
  error: 'rgba(200, 0, 0, 1)',
  fire: '#e73536',
  frost: '#D8D8D8',
  lightGrey: '#F2F2F2',
  lightBlue: '#D3E8FA',
  steel: '#CCCCCC',
  primaryThemeDark: '#2173BF',
  primaryThemeLight: '#2296f1',
  primaryThemeLighter: '#59b6ff',
  transparent: 'rgba(0,0,0,0)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
  primaryTheme: '#228be6',
  snow: '#fff',
  eggplant: '#251a34',
  border: '#483F53',
  banner: '#5F3E63',
  text: '#E0D7E5',
  noticeBackgroundTint: 'rgba(174, 62, 201, .1)',
  infoBackgroundTint: 'rgba(93, 180, 255, .1)',
  warningBackground: '#FDECEC',
  noticeBackground: '#F7ECF9',
  infoBackground: '#EFF7FF',
  warningText: '#f03e3e',
  noticeText: '#ae3ec9',
  infoText: '#228be6',
  editField: 'rgba(240, 222, 62, 0.1)',
  subbedBackgroundGrey: '#ededed',
  subbedTextGrey: '#5e5e5e',
  authShadowGrey: 'rgba(199, 199, 199, 0.50)',
  ghost: '#BCBBC1',
  successGreen: '#44b395',
  defaultGray: '#a3a8a3',
}

export default colors
