import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { logout } from '../services/api'

const AppHeader = ({ user, login_url, account_url, admin_url, download_url }) => {
  const logoutUser = () => {
    logout().then(() => {
      window.location.href = '/users/sign_in'
    })
  }

  const renderLink = (linkUrl, linkText) => (
    <Fragment>
      <a href={linkUrl}>{linkText}</a>
      <div className="vertical-divider" />
    </Fragment>
  )

  return (
    <header className="app-header">
      {user ? <strong>{user.first_name}</strong> : null}

      <nav className="app-links-container">
        {user ? (
          <Fragment>
            {account_url ? renderLink(account_url, 'Account') : null}
            {download_url ? renderLink(download_url, 'Download') : null}
            {admin_url ? renderLink(admin_url, 'Admin') : null}
            <a onClick={logoutUser}>Log Out</a>
          </Fragment>
        ) : (
          <a href={login_url}>Log in</a>
        )}
      </nav>
    </header>
  )
}

AppHeader.propTypes = {
  user: PropTypes.object,
}

export default AppHeader
