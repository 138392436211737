import { Colors, Metrics, Fonts } from '../../Themes'
import CreateResourceScreenStyles from './CreateResourceScreenStyles'

export default {
  ...CreateResourceScreenStyles,
  applyButton: {
    alignItems: 'center',
    backgroundColor: Colors.black50,
    borderRadius: Metrics.smallMargin,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    margin: Metrics.smallMargin,
  },
  applyButtonText: {
    color: Colors.snow,
    fontWeight: Fonts.weight.semibold,
    fontSize: Fonts.size.medium,
    lineHeight: 1,
    padding: Metrics.baseMargin,
    textAlign: 'center',
  },
  promoMessage: {
    color: Colors.successGreen,
  },
  editCardContainer: {
    flex: 1,
    flexDirection: 'column',
  },
}
