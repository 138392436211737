import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CloseIcon from './Icons/CloseIcon'
import InfoIcon from './Icons/InfoIcon'

class ToolTipModal extends Component {
  state = {
    showModal: false,
  }

  toggleModal = showModal => this.setState({ showModal: !showModal })

  render() {
    const { iconSize, toolTipContent } = this.props
    const { showModal } = this.state
    return (
      <div className="modal-container" onClick={() => this.toggleModal(showModal)}>
        <div className="modal-icon-wrapper">
          <InfoIcon iconSize={iconSize} />
        </div>
        <div className={`modal-body ${showModal ? 'show' : 'hidden'}`}>
          <div className="modal-content">
            <div className="modal-close">
              <CloseIcon iconSize={30} />
            </div>
            <div className="modal-text" dangerouslySetInnerHTML={{ __html: toolTipContent }} />
          </div>
        </div>
      </div>
    )
  }
}

ToolTipModal.propTypes = {
  iconSize: PropTypes.number,
  toolTipContent: PropTypes.string,
}

export default ToolTipModal
