import { Colors, Fonts } from '../../Themes'

export default {
  linkHeader: {
    color: Colors.black75,
    fontSize: Fonts.size.regular,
    fontWeight: Fonts.weight.semibold,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  linkInfo: {
    color: Colors.black75,
    fontSize: Fonts.size.regular,
    fontWeight: Fonts.weight.semibold,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  updateLink: {
    cursor: 'pointer',
    color: Colors.black50,
    fontSize: Fonts.size.medium,
    fontWeight: Fonts.weight.light,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  horizontalDivider: {
    borderBottomColor: Colors.black15,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    width: '100%',
  },
}
