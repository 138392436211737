import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import InfoIcon from './Icons/InfoIcon'

import styles from './Styles/ToolTipStyles'

const ToolTip = ({ iconSize, toolTipContent }) => (
  <a className="tooltip-container" data-tip={toolTipContent} style={styles.iconContainer}>
    <InfoIcon iconSize={iconSize} />
    <ReactTooltip
      className="instrukt-tooltip"
      html
      effect="solid"
      multiline
      globalEventOff="click touchstart"
      globalEventOn="click mousemove touchstart"
      wrapper="div"
    />
  </a>
)

ToolTip.propTypes = {
  iconSize: PropTypes.number,
  tooltipContent: PropTypes.string,
}

export default ToolTip
