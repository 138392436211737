import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import _ from 'lodash';

import { updateStudio } from '../services/api';

import SetupHeader from '../Components/SetupHeader';
import StudioForm from '../Components/StudioForm';

import styles from './Styles/CreateResourceScreenStyles';
import Stepper from '../Components/Stepper';
import BackButton from '../Components/BackButton';
import { STEPS } from '../utils/constants';

class EditStudioScreen extends Component {
  state = {
    name: '',
    mb_site_id: '',
    activatedId: '',
    needsNewActivation: false,
    warnAboutActivation: true,
  };

  componentDidMount = () => {
    const { name, mb_site_id, mb_sync_details } = this.props.studio;
    this.setState({
      name,
      mb_site_id: mb_site_id.toString(),
      activatedId: mb_site_id,
      needsNewActivation: _.isEmpty(mb_sync_details),
    });
  };

  updateInput = (input, attr) => {
    const { value } = input.target;
    const { activatedId } = this.state;

    if (attr === 'mb_site_id') {
      if (value !== activatedId.toString()) {
        this.setState({ needsNewActivation: true });
        const { warnAboutActivation } = this.state;
        if (warnAboutActivation) {
          NotificationManager.warning('You will need to Activate in Mindbody again.');
          this.setState({ warnAboutActivation: false });
        }
      } else {
        this.setState({
          needsNewActivation: false,
          warnAboutActivation: true,
        });
      }
    }

    this.setState({ [attr]: input.target.value });
  };

  handleSubmit = () => {
    const { name, mb_site_id } = this.state;
    const { studio } = this.props;
    if (this.validate()) {
      updateStudio({
        studio,
        attributes: { name, mb_site_id },
      }).then(response => this.handleResponse(response));
    }
  };

  validate = () => {
    if (_.some(this.state, attr => attr === '')) {
      NotificationManager.error('Missing required fields');
    } else {
      return true;
    }
  };

  handleResponse = response => {
    if (response.ok) {
      if (this.state.needsNewActivation) {
        window.location.href = '/studios/activate';
      } else {
        NotificationManager.success('Successfully updated studio!');
      }
    } else {
      NotificationManager.error(response.message);
    }
  };

  render() {
    const { mb_site_id, name, needsNewActivation } = this.state;

    return (
      <Fragment>
        <div className="screen-background">
          <BackButton />
          <div className="main-container no-padding">
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            <Stepper steps={STEPS.ACCOUNT_CREATION} current={3} className="account-stepper" />
            <div className="connect-studio-form-container">
              <div style={styles.headerTextWrapper}>
                <p style={styles.headerMainText}>Edit Your Studio</p>
              </div>
              <StudioForm
                name={name}
                mbSiteId={mb_site_id}
                handleSubmit={this.handleSubmit}
                updateInput={this.updateInput}
                submitText={needsNewActivation ? 'Get Activation Code' : 'Save Changes'}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

EditStudioScreen.propTypes = {
  studio: PropTypes.object,
};

export default EditStudioScreen;
