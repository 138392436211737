import notifications from '../utils/ant_notifications';

export const handleError = e => {
  const { errors = [] } = e;
  if (errors.length) {
    errors.forEach(error =>
      notifications.error({
        message: error,
      })
    );
  } else notifications.error();
  throw e;
};
