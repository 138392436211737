import React from 'react';
import PropTypes from 'prop-types';

import SubscriptionCard from '../Components/SubscriptionCard';

import styles from './Styles/SubscriptionsContainerStyles';

const SubscriptionsContainer = ({
  getTotalCost,
  plans,
  studioCounts,
  submitSubscription,
  loading = '',
}) => {
  // plans
  const basicPlan = plans.basic[0];
  const premiumPlan = plans.premium[0];

  const renderSubscriptionCard = (subscriptionType, subscription) => (
    <SubscriptionCard
      getTotalCost={getTotalCost}
      hasButton
      plans={plans}
      studioCounts={studioCounts}
      submitSubscription={submitSubscription}
      subscription={subscription}
      subscriptionType={subscriptionType}
      loading={loading}
    />
  );

  return (
    <div>
      <div style={styles.subscriptionCardsContainer} className="subscription-cards-container">
      {renderSubscriptionCard('basic', basicPlan)}
      {renderSubscriptionCard('premium', premiumPlan)}
      </div>
    </div>
  );
};

SubscriptionsContainer.propTypes = {
  baseQualifiedPlan: PropTypes.string,
  getTotalCost: PropTypes.func,
  plans: PropTypes.object,
  studioCounts: PropTypes.object,
  submitSubscription: PropTypes.func,
};

export default SubscriptionsContainer;
