import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getLabelStyle } from '../utils/inputValidation'

import PrimaryButton from './PrimaryButton'
import ToolTip from './ToolTip'
import ToolTipModal from './ToolTipModal'

import styles from '../Screens/Styles/CreateResourceScreenStyles'
import { Metrics } from '../Themes'

const StudioForm = ({ errorFields, name, mbSiteId, handleSubmit, updateInput, submitText }) => {
  const studioIdToolTipContent = `
      <p>Your Mindbody site ID is the number shown after <em>studioid=</em></p>
      <p>&nbsp;in the URL when you are logged in to your Mindbody account.</p>
      </br>
      <p>Your site ID is also shown in the bottom right hand corner of your Mindbody site webpage.</p>
      </br>
      <p>If you have a '-' sign at the beginning of your site ID,</p>
      <p>be sure to include it when you enter your site ID here.</p>
    `
  const bottomLabelStyle = { ...styles.bottomLabel, ...{ paddingRight: '16px' } }

  return (
    <Fragment>
      <label className="input-label" style={getLabelStyle(errorFields, 'name', styles.label)}>
        <input
          placeholder="Studio Name"
          style={styles.input}
          type="text"
          value={name}
          onChange={input => updateInput(input, 'name')}
          autoComplete={name}
        />
      </label>
      <label
        className="input-label"
        style={getLabelStyle(errorFields, 'mb_site_id', bottomLabelStyle)}
      >
        <input
          placeholder="Mind Body Site ID"
          style={styles.input}
          type="text"
          value={mbSiteId}
          onChange={input => updateInput(input, 'mb_site_id')}
          autoComplete={mbSiteId}
        />
        <ToolTip iconSize={Metrics.icons.headerIcon} toolTipContent={studioIdToolTipContent} />
        <ToolTipModal iconSize={Metrics.icons.headerIcon} toolTipContent={studioIdToolTipContent} />
      </label>
      <div style={styles.buttonsContainer} className="buttons-container">
        <PrimaryButton onPress={handleSubmit} text={submitText} block newCtaButton />
      </div>
    </Fragment>
  )
}

StudioForm.propTypes = {
  errorFields: PropTypes.array,
  name: PropTypes.string,
  mbSiteId: PropTypes.string,
  handleSubmit: PropTypes.func,
  updateInput: PropTypes.func,
  submitText: PropTypes.string,
}

export default StudioForm
